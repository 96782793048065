import { helpers } from '@vuelidate/validators'

const isAvailableEmailAddress = () =>
  helpers.withMessage(
    (result) => {
      const { $i18n } = useNuxtApp()

      result.$pending = false

      return result.$response.$message ?? $i18n.t('unavailable_email_address')
    },
    helpers.withAsync(async (value) => {
      const { $apiService } = useNuxtApp()

      try {
        const emailAddressIsAvailable = await $apiService.checkEmail({ email: value }).catch(() => false)

        return Promise.resolve({
          $valid: emailAddressIsAvailable,
        })
      } catch (error) {
        return Promise.resolve({
          $valid: false,
        })
      }
    }),
  )

export default isAvailableEmailAddress
