import { helpers } from '@vuelidate/validators'

const isValidVatNumber = (reactiveProp) =>
  helpers.withMessage(
    (result) => {
      const { $i18n } = useNuxtApp()

      result.$pending = false

      return result.$response.$message ?? $i18n.t('invalid_vat_number')
    },
    helpers.withAsync(async (value) => {
      const { $vatService, $i18n } = useNuxtApp()

      try {
        const vatNumberResult = await $vatService.getVatNumberDetails(value).catch(() => null)

        if (!vatNumberResult) {
          return Promise.resolve({
            $valid: false,
            $message: $i18n.t('vat_number_cannot_be_validated'),
          })
        }

        if (!vatNumberResult.valid) {
          return Promise.resolve({
            $valid: false,
          })
        }

        if (
          (reactiveProp.postalCode !== null || reactiveProp.country !== null) &&
          !$vatService.validateVatNumberWithPostalCodeAndCountry(vatNumberResult, reactiveProp)
        ) {
          return Promise.resolve({
            $valid: false,
            $message: $i18n.t('invalid_vat_number_with_address'),
          })
        }

        const addressFromVatNumberResult = $vatService.transformToAddress(vatNumberResult)

        // Set address from vatService response if null
        // Caution: reactiveProp data changed by reference!
        reactiveProp.name = reactiveProp.name === null ? addressFromVatNumberResult.name : reactiveProp.name
        reactiveProp.country = reactiveProp.country === null ? addressFromVatNumberResult.country : reactiveProp.country
        reactiveProp.address = reactiveProp.address === null ? addressFromVatNumberResult.address : reactiveProp.address
        reactiveProp.postalCode =
          reactiveProp.postalCode === null ? addressFromVatNumberResult.postalCode : reactiveProp.postalCode
        reactiveProp.city = reactiveProp.city === null ? addressFromVatNumberResult.city : reactiveProp.city

        return Promise.resolve({
          $valid: vatNumberResult.valid,
        })
      } catch (error) {
        return Promise.resolve({
          $valid: false,
        })
      }
    }),
  )

export default isValidVatNumber
