import { required, email, sameAs, minLength, createI18nMessage, requiredIf, minValue } from '@vuelidate/validators'
import isValidVatNumber from '~/lib/validators/is-valid-vat-number'
import isAvailableEmailAddress from '~/lib/validators/is-available-email-address'
import isValidPostalCode from '~/lib/validators/is-valid-postal-code'

export default function () {
  const { t: $t } = useI18n()
  const withI18nMessage = createI18nMessage({ t: $t })

  return {
    required: withI18nMessage(required),
    email: withI18nMessage(email),
    sameAs: withI18nMessage(sameAs, { withArguments: true }),
    minLength: withI18nMessage(minLength, { withArguments: true }),
    minValue: withI18nMessage(minValue, { withArguments: true }),
    requiredIf: withI18nMessage(requiredIf, { withArguments: true }),

    // Custom validators
    isValidVatNumber,
    isAvailableEmailAddress,
    isValidPostalCode,
  }
}
