import { helpers } from '@vuelidate/validators'
import { COUNTRIES } from '~/lib/constants/countries'

const isValidPostalCode = (reactiveProp) =>
  helpers.withMessage(
    (result) => {
      const { $i18n } = useNuxtApp()

      const selectedCountryData = computed(() => {
        if (!reactiveProp.country) {
          return null
        }

        return COUNTRIES.find((country) => country.iso2code === reactiveProp.country)
      })
      const postalCodeExample = computed(() => selectedCountryData?.value?.postalCodeExample)

      return result.$response.$message ?? $i18n.t('invalid_postal_code', { format: postalCodeExample.value })
    },
    (value) => {
      const selectedCountryData = computed(() => {
        if (!reactiveProp.country) {
          return null
        }

        return COUNTRIES.find((country) => country.iso2code === reactiveProp.country)
      })
      const postalCodeRegex = computed(() => selectedCountryData?.value?.regex)

      if (!postalCodeRegex.value) {
        return true
      }

      return postalCodeRegex.value.test(value)
    },
  )

export default isValidPostalCode
