export const COUNTRIES = [
  {
    name: 'Austria',
    iso2code: 'AT',
    regex: /^\d{4}$/, // Austrian postal codes are 4 digits
    postalCodeExample: '1010',
  },
  {
    name: 'Belgium',
    iso2code: 'BE',
    regex: /^\d{4}$/, // Belgian postal codes are 4 digits
    postalCodeExample: '1000',
  },
  {
    name: 'Bulgaria',
    iso2code: 'BG',
    regex: /^\d{4}$/, // Bulgarian postal codes are 4 digits
    postalCodeExample: '1000',
  },
  {
    name: 'Cyprus',
    iso2code: 'CY',
    regex: /^\d{4}$/, // Cypriot postal codes are 4 digits
    postalCodeExample: '1000',
  },
  {
    name: 'Czech Republic',
    iso2code: 'CZ',
    regex: /^\d{3}\s?\d{2}$/, // Czech postal codes with optional space
    postalCodeExample: '110 00',
  },
  {
    name: 'Denmark',
    iso2code: 'DK',
    regex: /^\d{4}$/, // Danish postal codes are 4 digits
    postalCodeExample: '1000',
  },
  {
    name: 'Estonia',
    iso2code: 'EE',
    regex: /^\d{5}$/, // Estonian postal codes are 5 digits
    postalCodeExample: '10123',
  },
  {
    name: 'Finland',
    iso2code: 'FI',
    regex: /^\d{5}$/, // Finnish postal codes are 5 digits
    postalCodeExample: '00100',
  },
  {
    name: 'France',
    iso2code: 'FR',
    regex: /^\d{5}$/, // French postal codes are 5 digits
    postalCodeExample: '75008',
  },
  {
    name: 'Germany',
    iso2code: 'DE',
    regex: /^\d{5}$/, // German postal codes are 5 digits
    postalCodeExample: '10115',
  },
  {
    name: 'Greece',
    iso2code: 'GR',
    regex: /^\d{5}$/, // Greek postal codes are 5 digits
    postalCodeExample: '10558',
  },
  {
    name: 'Hungary',
    iso2code: 'HU',
    regex: /^\d{4}$/, // Hungarian postal codes are 4 digits
    postalCodeExample: '1007',
  },
  {
    name: 'Ireland',
    iso2code: 'IE',
    regex: /^[A-Za-z]\d{2}\s?[A-Za-z\d]{4}$/, // Irish Eircode format with optional space
    postalCodeExample: 'D02 X285',
  },
  {
    name: 'Italy',
    iso2code: 'IT',
    regex: /^\d{5}$/, // Italian postal codes are 5 digits
    postalCodeExample: '00100',
  },
  {
    name: 'Croatia',
    iso2code: 'HR',
    regex: /^\d{5}$/, // Croatian postal codes are 5 digits
    postalCodeExample: '10000',
  },
  {
    name: 'Latvia',
    iso2code: 'LV',
    regex: /^LV-\d{4}$/, // Latvian postal codes in format LV-1234
    postalCodeExample: 'LV-1050',
  },
  {
    name: 'Lithuania',
    iso2code: 'LT',
    regex: /^LT-\d{5}$/, // Lithuanian postal codes in format LT-12345
    postalCodeExample: 'LT-01100',
  },
  {
    name: 'Luxembourg',
    iso2code: 'LU',
    regex: /^\d{4}$/, // Luxembourg postal codes are 4 digits
    postalCodeExample: '1009',
  },
  {
    name: 'Malta',
    iso2code: 'MT',
    regex: /^[A-Za-z]{3}\s?\d{2,4}$/, // Maltese postal codes with optional space
    postalCodeExample: 'BKR 3000',
  },
  {
    name: 'Nederland',
    iso2code: 'NL',
    regex: /^\d{4}\s?[A-Za-z]{2}$/, // Dutch postal codes with optional space
    postalCodeExample: '1011 AB',
  },
  {
    name: 'Portugal',
    iso2code: 'PT',
    regex: /^\d{4}-\d{3}$/, // Portuguese postal codes in format 1234-567
    postalCodeExample: '1000-001',
  },
  {
    name: 'Poland',
    iso2code: 'PL',
    regex: /^\d{2}-\d{3}$/, // Polish postal codes in format 12-345
    postalCodeExample: '00-001',
  },
  {
    name: 'Romania',
    iso2code: 'RO',
    regex: /^\d{6}$/, // Romanian postal codes are 6 digits
    postalCodeExample: '010011',
  },
  {
    name: 'Slovenia',
    iso2code: 'SI',
    regex: /^\d{4}$/, // Slovenian postal codes are 4 digits
    postalCodeExample: '1000',
  },
  {
    name: 'Slovakia',
    iso2code: 'SK',
    regex: /^\d{3}\s?\d{2}$/, // Slovak postal codes with optional space
    postalCodeExample: '811 02',
  },
  {
    name: 'Spain',
    iso2code: 'ES',
    regex: /^\d{5}$/, // Spanish postal codes are 5 digits
    postalCodeExample: '28013',
  },
  {
    name: 'Sweden',
    iso2code: 'SE',
    regex: /^\d{3}\s?\d{2}$/, // Swedish postal codes with optional space
    postalCodeExample: '114 55',
  },
]

export const COUNTRIES_SELECT_OPTIONS = COUNTRIES.map((country) => {
  return {
    key: country.name,
    value: country.iso2code,
  }
})
